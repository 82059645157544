import { NodeToolbar, Position, useNodeId } from '@xyflow/react';
import React from 'react';

import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import { useFlyoutContext } from '../../../../shared/FloatingUIFlyout/FlyoutProvider';
import FlyoutTrigger from '../../../../shared/FloatingUIFlyout/FlyoutTrigger';
import ConnectionsFlyout from './ConnectionsFlyout';
import ConnectionsIconButton from './ConnectionsIconButton';

const ConnectionsIconToolbar = () => {
  const nodeId = useNodeId();
  const {
    flowchartHandlers: { getNodeConnections },
  } = useFlowchart();
  const nodeConnections = getNodeConnections(nodeId);
  const { open: connectionsFlyoutOpen } = useFlyoutContext();

  return (
    <>
      {nodeConnections.length > 0 && (
        <NodeToolbar isVisible offset={-15} position={Position.Bottom}>
          <FlyoutTrigger>
            <ConnectionsIconButton active={connectionsFlyoutOpen} connections={nodeConnections} />
          </FlyoutTrigger>
          <ConnectionsFlyout connections={nodeConnections} />
        </NodeToolbar>
      )}
    </>
  );
};

export default ConnectionsIconToolbar;
