import {
  Placement,
  autoUpdate,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useMemo } from 'react';

import { RegisteredMenuId } from '../../../../lib/idRegistry';
import useActiveMenuHandler from '../../../design_system/useActiveMenuHandler';

type FlyoutOptions = {
  id: RegisteredMenuId;
  placement?: Placement;
};

/**
 * The useFlyout hook sets up the basic configuration of a flyout using the Floating UI
 * library, and returns several properties and utilities that can be used in React
 * components to connect elements of the flyout.
 *
 * It is expected that the result of this hook will be passed to a FlyoutProvider that
 * wraps the flyout elements, including:
 * * FlyoutTrigger - When clicked, will open the flyout
 * * FloatingUIFlyout - A wrapper for the actual flyout
 *
 * By default, a flyout will be positioned relative to the trigger. To position the
 * flyout relative to a different element, use the refs returned from this hook and set
 * the position element ref to refs.setPositionReference.
 */
const useFlyout = ({ id, placement = 'bottom-start' }: FlyoutOptions) => {
  const { handleMenuClick, isMenuOpen } = useActiveMenuHandler({
    menuId: id,
  });

  const open = isMenuOpen;
  const setOpen = handleMenuClick;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(10)],
  });

  const { context } = data;

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'click',
  });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  );
};

export default useFlyout;
