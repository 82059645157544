import { useNodeId } from '@xyflow/react';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import { ShapeType } from '../../../../../../types/Flowchart';
import Shape from '../../../shared/nodes/ShapeNode/shapes/Shape';
import { ShapeItemWrapper } from '../../../shared/Panel/ShapeMenu/ShapeMenuItem';

const ToolbarShapeItemWrapper = styled.div<{ active: boolean }>`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background-color: ${({ theme: { vars }, active }) =>
    active ? vars.accentSubdued1 : vars.foundationSurface1};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme: { vars }, active }) =>
      active ? vars.accentSubdued2 : vars.foundationHover};
  }
`;

const PreviewShape = styled(Shape)<{ $active: boolean }>`
  display: block;
  fill: ${({ theme: { vars } }) => vars.foundationSurface1};
  stroke: ${({
    $active,
    theme: {
      vars: { accentPrimaryDefault, textDefault },
    },
  }) => ($active ? accentPrimaryDefault : textDefault)};
`;

const ShapePreview = ({ type, active }: { type: ShapeType; active: boolean }) => {
  const theme = useTheme();

  return (
    <ShapeItemWrapper>
      <PreviewShape
        $active={active}
        fill={theme.vars.foundationSurface1}
        height={25}
        strokeWidth={2}
        type={type}
        width={25}
      />
    </ShapeItemWrapper>
  );
};

const ToolbarShapeItem = ({
  type,
  onClose,
  active,
}: {
  type: ShapeType;
  onClose: () => void;
  active: boolean;
}) => {
  const { flowchartHandlers } = useFlowchart();
  const { setShapeType } = flowchartHandlers;
  const nodeId = useNodeId();

  if (nodeId === null) {
    return null;
  }

  const handleShapeClick = () => {
    setShapeType(nodeId, type);
    onClose();
  };

  return (
    <ToolbarShapeItemWrapper active={active} onClick={handleShapeClick}>
      <ShapePreview active={active} type={type} />
    </ToolbarShapeItemWrapper>
  );
};

export default ToolbarShapeItem;
