import {
  ControlLinePosition,
  Dimensions,
  Node,
  NodeDimensionChange,
  NodePositionChange,
  XYPosition,
} from '@xyflow/react';

type Args = {
  positionChange?: NodePositionChange;
  dimensionsChange?: NodeDimensionChange;
  resizeDirection?: Set<ControlLinePosition>;
  nodes: Node[];
  distance?: number;
};

type GetHelperLinesResult = {
  horizontal?: number;
  vertical?: number;
  snapPosition: Partial<XYPosition>;
  snapDimensions: Partial<Dimensions>;
};

// This utility function can be called with a position and/or dimensions change
// (inside onNodesChange). It checks all other nodes and calculated the helper line
// positions and the position where the current node should snap to
export function getHelperLines({
  positionChange,
  dimensionsChange,
  resizeDirection,
  nodes,
  distance = 5,
}: Args): GetHelperLinesResult {
  const defaultResult = {
    horizontal: undefined,
    vertical: undefined,
    snapPosition: { x: undefined, y: undefined },
    snapDimensions: { width: undefined, height: undefined },
  };
  const nodeId = positionChange?.id || dimensionsChange?.id;
  const nodeA = nodes.find((node) => node.id === nodeId);

  if (!nodeA) {
    return defaultResult;
  }

  const isPositionChange = positionChange !== undefined;
  const isDimensionsChange = dimensionsChange !== undefined;
  const allowSnapTop = !isDimensionsChange || resizeDirection?.has('top');
  const allowSnapRight = !isDimensionsChange || resizeDirection?.has('right');
  const allowSnapBottom = !isDimensionsChange || resizeDirection?.has('bottom');
  const allowSnapLeft = !isDimensionsChange || resizeDirection?.has('left');

  const nodeAPosition = positionChange?.position ?? nodeA.position;
  const nodeADimensions = dimensionsChange?.dimensions ?? {
    width: nodeA.width,
    height: nodeA.height,
  };
  const nodeABounds = {
    left: nodeAPosition.x,
    right: nodeAPosition.x + (nodeADimensions.width ?? 0),
    top: nodeAPosition.y,
    bottom: nodeAPosition.y + (nodeADimensions.height ?? 0),
    centerX: nodeAPosition.x + (nodeADimensions.width ?? 0) / 2,
    centerY: nodeAPosition.y + (nodeADimensions.height ?? 0) / 2,
    width: nodeADimensions.width ?? 0,
    height: nodeADimensions.height ?? 0,
  };

  let horizontalDistance = distance;
  let verticalDistance = distance;

  return nodes
    .filter((node) => node.id !== nodeA.id)
    .reduce<GetHelperLinesResult>((result, nodeB) => {
      const nodeBBounds = {
        left: nodeB.position.x,
        right: nodeB.position.x + (nodeB.width ?? 0),
        top: nodeB.position.y,
        bottom: nodeB.position.y + (nodeB.height ?? 0),
        centerX: nodeB.position.x + (nodeB.width ?? 0) / 2,
        centerY: nodeB.position.y + (nodeB.height ?? 0) / 2,
        width: nodeB.width ?? 0,
        height: nodeB.height ?? 0,
      };

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //  |
      //  |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);

      if (distanceLeftLeft < verticalDistance && allowSnapLeft) {
        if (isPositionChange) {
          result.snapPosition.x = nodeBBounds.left;
        }
        if (isDimensionsChange) {
          result.snapDimensions.width = nodeABounds.left - nodeBBounds.left + nodeABounds.width;
        }
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceLeftLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceRightRight = Math.abs(nodeABounds.right - nodeBBounds.right);

      if (distanceRightRight < verticalDistance && allowSnapRight) {
        if (isPositionChange) {
          result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
        }
        if (isDimensionsChange) {
          result.snapDimensions.width = nodeBBounds.right - nodeABounds.right + nodeABounds.width;
        }
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceRightRight;
      }

      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     A     |
      //              |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);

      if (distanceLeftRight < verticalDistance && allowSnapLeft) {
        if (isPositionChange) {
          result.snapPosition.x = nodeBBounds.right;
        }
        if (isDimensionsChange) {
          result.snapDimensions.width = nodeABounds.left - nodeBBounds.right + nodeABounds.width;
        }
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceLeftRight;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     B     |
      //              |___________|
      const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);

      if (distanceRightLeft < verticalDistance && allowSnapRight) {
        if (isPositionChange) {
          result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
        }
        if (isDimensionsChange) {
          result.snapDimensions.width = nodeBBounds.left - nodeABounds.right + nodeABounds.width;
        }
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceRightLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|     |___________|
      const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);

      if (distanceTopTop < horizontalDistance && allowSnapTop) {
        if (isPositionChange) {
          result.snapPosition.y = nodeBBounds.top;
        }
        if (isDimensionsChange) {
          // When resizing via the top-right handle, some dimensions changes include a position
          // change (when y goes up or down) and some don't; when there is a position change,
          // we calculate the snap height based on the changed height, but otherwise we use the
          // node's current height because we want it to stay fixed.
          if (isPositionChange) {
            result.snapDimensions.height = nodeABounds.top - nodeBBounds.top + nodeABounds.height;
          } else {
            result.snapDimensions.height = nodeA.height;
          }
        }
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceTopTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|_________________
      //                    |           |
      //                    |     B     |
      //                    |___________|
      const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);

      if (distanceBottomTop < horizontalDistance && allowSnapBottom) {
        if (isPositionChange) {
          result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
        }
        if (isDimensionsChange) {
          result.snapDimensions.height = nodeBBounds.top - nodeABounds.bottom + nodeABounds.height;
        }
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceBottomTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|_____|___________|
      const distanceBottomBottom = Math.abs(nodeABounds.bottom - nodeBBounds.bottom);

      if (distanceBottomBottom < horizontalDistance && allowSnapBottom) {
        if (isPositionChange) {
          result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
        }
        if (isDimensionsChange) {
          result.snapDimensions.height =
            nodeBBounds.bottom - nodeABounds.bottom + nodeABounds.height;
        }
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceBottomBottom;
      }

      //                    |‾‾‾‾‾‾‾‾‾‾‾|
      //                    |     B     |
      //                    |           |
      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
      //  |     A     |
      //  |___________|
      const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);

      if (distanceTopBottom < horizontalDistance && allowSnapTop) {
        if (isPositionChange) {
          result.snapPosition.y = nodeBBounds.bottom;
        }
        if (isDimensionsChange) {
          // (See comment for distanceTopTop)
          if (isPositionChange) {
            result.snapDimensions.height =
              nodeABounds.top - nodeBBounds.bottom + nodeABounds.height;
          } else {
            result.snapDimensions.height = nodeA.height;
          }
        }
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceTopBottom;
      }

      // Center alignments

      // If nodes are already aligned on one side, skip center helper line, as it supersedes
      // the helper line from the opposite side and causes a premature snap.
      const allowSnapCenterX =
        !isDimensionsChange ||
        (nodeABounds.left !== nodeBBounds.left && nodeABounds.right !== nodeBBounds.right);
      const distanceCenterX = Math.abs(nodeABounds.centerX - nodeBBounds.centerX);
      if (distanceCenterX < verticalDistance && allowSnapCenterX) {
        if (isPositionChange) {
          result.snapPosition.x = nodeBBounds.centerX - nodeABounds.width / 2;
        }
        if (isDimensionsChange) {
          const distanceCenter = nodeABounds.centerX - nodeBBounds.centerX;
          const snapDistance =
            2 * (resizeDirection?.has('left') ? distanceCenter : -1 * distanceCenter);

          result.snapDimensions.width = snapDistance + nodeABounds.width;
        }
        result.vertical = nodeBBounds.centerX;
        verticalDistance = distanceCenterX;
      }

      const allowSnapCenterY =
        !isDimensionsChange ||
        (nodeABounds.top !== nodeBBounds.top && nodeABounds.bottom !== nodeBBounds.bottom);
      const distanceCenterY = Math.abs(nodeABounds.centerY - nodeBBounds.centerY);
      if (distanceCenterY < horizontalDistance && allowSnapCenterY) {
        if (isPositionChange) {
          result.snapPosition.y = nodeBBounds.centerY - (nodeA?.height || 0) / 2;
        }
        if (isDimensionsChange) {
          const distanceCenter = nodeABounds.centerY - nodeBBounds.centerY;
          // (See comment for distanceTopTop)
          if (resizeDirection?.has('top')) {
            if (isPositionChange) {
              result.snapDimensions.height = 2 * distanceCenter + nodeABounds.height;
            } else {
              result.snapDimensions.height = nodeA.height;
            }
          } else {
            result.snapDimensions.height = -2 * distanceCenter + nodeABounds.height;
          }
        }
        result.horizontal = nodeBBounds.centerY;
        horizontalDistance = distanceCenterY;
      }

      return result;
    }, defaultResult);
}
