import { Handle, Position, useConnection } from '@xyflow/react';
import React from 'react';
import { CSSProperties, useTheme } from 'styled-components';

import { useFlowchart } from '../../../../../contexts/FlowchartControlProvider';

type EdgeHandleProps = {
  position: Position;
  isTargetNode: boolean;
  targetHandleId: string | null | undefined;
  showIconsToolbar?: boolean;
};

const EdgeHandle = ({
  position,
  isTargetNode,
  targetHandleId,
  showIconsToolbar,
}: EdgeHandleProps) => {
  const { readonly } = useFlowchart();
  const {
    vars: { accentPrimaryDefault, foundationSurface1, accentSubdued4 },
    constants: { borderWidthSm },
  } = useTheme();

  const baseHandleStyle: CSSProperties = {
    backgroundColor: foundationSurface1,
    border: `calc(${borderWidthSm} / var(--flowchart-zoom, 1)) solid ${accentSubdued4}`,
    width: `calc(0.75rem / var(--flowchart-zoom, 1))`,
    height: `calc(0.75rem / var(--flowchart-zoom, 1))`,
    boxSizing: 'content-box',
    bottom:
      showIconsToolbar && position === 'bottom' ? `calc(-1rem / var(--flowchart-zoom, 1))` : 0,
  };

  const targetHandleStyle: CSSProperties = {
    ...baseHandleStyle,
    backgroundColor: targetHandleId === position ? accentPrimaryDefault : foundationSurface1,
    border: `calc(${borderWidthSm} / var(--flowchart-zoom, 1)) solid ${accentPrimaryDefault}`,
    opacity: 1,
    width: `calc(0.5rem / var(--flowchart-zoom, 1))`,
    height: `calc(0.5rem / var(--flowchart-zoom, 1))`,
  };

  const handleCircleStyle: CSSProperties = {
    width: `calc(0.25rem / var(--flowchart-zoom, 1))`,
    height: `calc(0.25rem / var(--flowchart-zoom, 1))`,
    opacity: isTargetNode ? 0 : 1,
  };

  return (
    <Handle
      className='hover-circle'
      id={position}
      isConnectable={!readonly}
      position={position}
      style={isTargetNode ? targetHandleStyle : baseHandleStyle}
      type='source'
    >
      <div className='handle-circle' style={handleCircleStyle} />
    </Handle>
  );
};

type Props = {
  isTargetNode: boolean;
  showIconsToolbar: boolean;
};

const EdgeHandles = ({ isTargetNode, showIconsToolbar }: Props) => {
  const edgeConnection = useConnection();
  const targetHandleId = edgeConnection.toHandle?.id;

  return (
    <>
      <EdgeHandle
        isTargetNode={isTargetNode}
        position={Position.Top}
        targetHandleId={targetHandleId}
      />
      <EdgeHandle
        isTargetNode={isTargetNode}
        position={Position.Right}
        targetHandleId={targetHandleId}
      />
      <EdgeHandle
        isTargetNode={isTargetNode}
        position={Position.Bottom}
        showIconsToolbar={showIconsToolbar}
        targetHandleId={targetHandleId}
      />
      <EdgeHandle
        isTargetNode={isTargetNode}
        position={Position.Left}
        targetHandleId={targetHandleId}
      />
    </>
  );
};

export default EdgeHandles;
