import { useNodeId } from '@xyflow/react';
import React from 'react';
import styled from 'styled-components';

import { useFlowchart } from '../../../../../../../contexts/FlowchartControlProvider';
import initTranslations from '../../../../../../../lib/initTranslations';
import { useGetSearchCompanyResultsQuery } from '../../../../../../../redux/services/resourceApis/searches/searchesApi';
import { ConnectionData } from '../../../../../../../types/Flowchart';
import { fontSm5 } from '../../../../../../styled/TypeSystem';
import ConnectionSearchResultsRows from './ConnectionSearchResultsRows';

const SearchResults = styled.div`
  position: absolute;
  top: 2.25rem;
  width: 100%;
  max-height: 13rem;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { vars } }) => `1px, solid, ${vars.borderSurface2}`};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  overflow-y: auto;
`;

const EmptyState = styled.div`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  text-align: center;
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
  ${fontSm5};
`;

const t = initTranslations('curriculums_view.flowchart.shape_toolbar.connections.flyout.search');

const ConnectionsSearchResults = ({
  onSearchResultClick,
  searchValue,
}: {
  onSearchResultClick: () => void;
  searchValue: string;
}) => {
  const {
    flowchartHandlers: { storeConnectionsData, addNodeConnection },
  } = useFlowchart();
  const nodeId = useNodeId();
  const { data, isLoading, isFetching } = useGetSearchCompanyResultsQuery({
    page: 1,
    search: searchValue,
    filter: ['groups', 'people', 'subjects', 'topics', 'steps', 'tests', 'flowcharts'],
  });

  const hasResults = data && data.paginatedResult.length > 0;

  const setNodeConnection = (connection: ConnectionData) => {
    if (nodeId) {
      storeConnectionsData([connection]);
      addNodeConnection({ nodeId, connection });
    }
    onSearchResultClick();
  };

  if (isLoading || isFetching) return null;

  return (
    <SearchResults>
      <>
        {!hasResults && <EmptyState>{t('empty_state')}</EmptyState>}
        {hasResults && (
          <ConnectionSearchResultsRows
            onClick={setNodeConnection}
            searchResults={data.paginatedResult}
          />
        )}
      </>
    </SearchResults>
  );
};

export default ConnectionsSearchResults;
