import { useNodeId } from '@xyflow/react';
import React from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../../contexts/EditorContext';
import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import { NodeConnection } from '../../../../../../types/Flowchart';
import ConnectionRow from '../ConnectionRow/ConnectionRow';

const RowsContainer = styled.div`
  max-height: 12rem;
  overflow-y: auto;
`;

type Props = {
  connections: NodeConnection[];
  readonly: boolean;
};

const ConnectionsFlyoutList = ({ connections, readonly }: Props) => {
  const {
    flowchartHandlers: { connectionsData, removeNodeConnection, removeEditorMentions },
  } = useFlowchart();
  const nodeId = useNodeId();
  const { editor } = useEditorContext();

  const onDeleteClick = (connection: NodeConnection) => {
    if (nodeId) {
      removeNodeConnection({ nodeId, connection });
      removeEditorMentions({ editor, connection });
    }
  };

  return (
    <RowsContainer>
      {connections.map(({ type, id }) => {
        const connectionData = connectionsData[type] && connectionsData[type][id];

        return (
          <ConnectionRow
            connection={connectionData || { type, id }}
            deleted={!connectionData}
            key={`${type}-${id}-row`}
            onDeleteClick={readonly ? undefined : () => onDeleteClick({ type, id })}
          />
        );
      })}
    </RowsContainer>
  );
};

export default ConnectionsFlyoutList;
