import { useNodeId } from '@xyflow/react';
import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import initTranslations from '../../../../../../lib/initTranslations';
import ColorPickerTrigger from '../../../../../design_system/buttons/DeprecatedColorPicker/ColorPickerTrigger';
import ColorPicker from '../../../../../design_system/ColorPicker/ColorPicker';
import ColorPickerInput from '../../../../../design_system/ColorPicker/ColorPickerInput';
import { SHAPE_COLORS } from '../../../../../design_system/ColorPicker/constants';
import FillSwatch from '../../../../../design_system/ColorPicker/Swatches/FillSwatch';
import { SwatchesWrapper } from '../../../../../design_system/ColorPicker/Swatches/styles';
import { paletteColorDecoder } from '../../../../../design_system/helpers';
import useActiveMenuHandler from '../../../../../design_system/useActiveMenuHandler';

const t = initTranslations('curriculums_view.flowchart.toolbar');

let lastNodeId: string | null = null;
const ShapeColorPicker = () => {
  const nodeId = useNodeId();
  const COLOR_PICKER_ID = `shape-${nodeId}-color-picker` as const;
  const { closeMenu, isMenuOpen } = useActiveMenuHandler({ menuId: COLOR_PICKER_ID });

  const {
    palettes,
    vars: { foundationSurface1 },
  } = useTheme();
  const {
    flowchartHandlers: { getNode, setNodes },
  } = useFlowchart();
  const node = nodeId ? getNode(nodeId) : null;
  const handleColorChange = (color: string) => {
    if (nodeId && node) {
      setNodes((nodes) =>
        nodes.map((n) =>
          n.id === nodeId ? { ...n, data: { ...n.data, backgroundColor: color } } : n
        )
      );
    }
    closeMenu();
  };

  useEffect(() => {
    // Close the menu when de-selecting current node by clicking on a different node
    if (nodeId !== lastNodeId) {
      lastNodeId = nodeId;
      closeMenu();
    }
    // Close the menu when de-selecting node by clicking on canvas
    if (!node?.selected) {
      closeMenu();
    }
  }, [closeMenu, node?.selected, nodeId]);

  const activeColor = node?.data?.backgroundColor || foundationSurface1;
  return (
    <ColorPicker
      flyoutId={COLOR_PICKER_ID}
      strategy='absolute'
      trigger={
        <ColorPickerTrigger
          active={isMenuOpen}
          ariaLabel={t('shape_color_picker_aria_label')}
          buttonId='flowchart-shape-color-button'
          color={activeColor}
          type='shape-color'
        />
      }
    >
      <SwatchesWrapper rowCount={8}>
        {SHAPE_COLORS.map((color) => {
          return (
            <FillSwatch
              activeColor={activeColor}
              color={paletteColorDecoder(palettes, color)}
              key={color}
              onClick={handleColorChange}
            />
          );
        })}
      </SwatchesWrapper>
      <ColorPickerInput color={activeColor} onChange={handleColorChange} />
    </ColorPicker>
  );
};

export default ShapeColorPicker;
