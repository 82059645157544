import { JSONContent } from '@tiptap/core';
import { Edge, Node, ReactFlowJsonObject } from '@xyflow/react';

import { CurriculumElementStatus } from './CurriculumElement';
import { Emoji } from './Emoji';

export type Flowchart = {
  content: ReactFlowJsonObject<ShapeNode> | null;
  emoji: Emoji;
  id: number;
  title: string;
  status: CurriculumElementStatus;
};

export const shapeTypes = [
  'rectangle',
  'circle',
  'diamond',
  'triangle',
  'cylinder',
  'text',
] as const;

export type ShapeType = (typeof shapeTypes)[number];

/* Helper type to enforce shape implementations on objects.
 * IndexedByShape<number> will index an object by shape type where the object
 * must have every ShapeType as a key, and every value must be a number.
 * Will yield TS errors if a ShapeType key is missing or other keys are provided.
 */
export type IndexedByShape<T> = { [key in ShapeType]: T };

type ShapeNodeLabel = JSONContent | null;

type ShapeNodeData = {
  type: ShapeType;
  label: ShapeNodeLabel;
  fontSize: number;
  isEditing: boolean;
  connections: NodeConnection[];
  backgroundColor: string;
};

export type ShapeNode = Node<ShapeNodeData, 'shape'>;

type SerializedShapeNode = Pick<
  ShapeNode,
  | 'id'
  | 'position'
  | 'type'
  | 'sourcePosition'
  | 'targetPosition'
  | 'hidden'
  | 'style'
  | 'width'
  | 'height'
  | 'parentId'
  | 'zIndex'
  | 'extent'
  | 'expandParent'
  | 'ariaLabel'
  | 'data'
> & {
  data: Pick<ShapeNodeData, 'type' | 'label' | 'fontSize' | 'connections'>;
};

type EditableLabelEdgeData = {
  label: string;
};

export type EditableLabelEdge = Edge<EditableLabelEdgeData>;

type SerializedEditableLabelEdge = Pick<
  EditableLabelEdge,
  | 'id'
  | 'type'
  | 'source'
  | 'target'
  | 'style'
  | 'sourceHandle'
  | 'targetHandle'
  | 'hidden'
  | 'animated'
  | 'markerStart'
  | 'markerEnd'
  | 'zIndex'
  | 'interactionWidth'
  | 'ariaLabel'
> & {
  data: Pick<EditableLabelEdgeData, 'label'>;
};

export type SerializedNode = SerializedShapeNode; // | SerializedOtherNode
export type SerializedEdge = SerializedEditableLabelEdge; // | SerializedOtherEdge

/**
 *  Node Connections
 */
export const nodeConnectionModels = [
  'Curriculum',
  'Course',
  'Survey::Survey',
  'Flowchart',
  'Step',
  'Group',
  'User',
] as const;

export type NodeConnectionType = (typeof nodeConnectionModels)[number];

export type NodeConnection = {
  id: number;
  type: NodeConnectionType;
};

type NodeConnectionDataType<T extends NodeConnectionType, B> = {
  id: number;
  type: T;
} & B;

type CurriculumConnection = NodeConnectionDataType<
  'Curriculum',
  {
    emoji: Emoji;
    title: string;
  }
>;

type CourseConnection = NodeConnectionDataType<
  'Course',
  {
    emoji: Emoji;
    title: string;
  }
>;

type SurveyConnection = NodeConnectionDataType<
  'Survey::Survey',
  {
    emoji: Emoji;
    title: string;
  }
>;

type FlowchartConnection = NodeConnectionDataType<
  'Flowchart',
  {
    emoji: Emoji;
    title: string;
  }
>;

type StepConnection = NodeConnectionDataType<
  'Step',
  {
    emoji: Emoji;
    title: string;
  }
>;

type GroupConnection = NodeConnectionDataType<
  'Group',
  {
    name: string;
  }
>;

type UserConnection = NodeConnectionDataType<
  'User',
  {
    name: string;
    avatar: string | null;
  }
>;

export type ConnectionData =
  | CurriculumConnection
  | CourseConnection
  | SurveyConnection
  | FlowchartConnection
  | StepConnection
  | GroupConnection
  | UserConnection;
