import { NodeResizer, ResizeDragEvent, useKeyPress } from '@xyflow/react';
import React, { CSSProperties, useCallback } from 'react';
import { useTheme } from 'styled-components';

import { useFlowchart } from '../../../../../contexts/FlowchartControlProvider';
import { getResizeDirection } from '../../lib/getResizeDirection';
import { RESIZE_HANDLE_SIZE } from '../constants';

type Props = {
  isVisible: boolean;
};

const ResizeControls = ({ isVisible }: Props) => {
  const {
    flowchartHandlers: { setActiveResizeDirection },
  } = useFlowchart();
  const shiftKeyPressed = useKeyPress('Shift');
  const {
    vars: { accentPrimaryDefault, borderDefault, foundationSurface1, shadowCenterMedium },
    constants: { borderRadiusSm, borderWidthSm },
  } = useTheme();

  const setResizeDirection = useCallback(
    (event: ResizeDragEvent) => {
      setActiveResizeDirection(getResizeDirection(event.sourceEvent.srcElement.classList));
    },
    [setActiveResizeDirection]
  );

  const resetResizeDirection = useCallback(() => {
    setActiveResizeDirection(new Set());
  }, [setActiveResizeDirection]);

  const resizeLineStyle = {
    borderColor: accentPrimaryDefault,
  };

  const invisibleResizeLineStyle: CSSProperties = {
    border: 'none',
    backgroundColor: 'transparent',
    zIndex: 1,
  };

  const resizeHandleStyle: CSSProperties = {
    width: `calc(${RESIZE_HANDLE_SIZE} / var(--flowchart-zoom, 1))`,
    height: `calc(${RESIZE_HANDLE_SIZE} / var(--flowchart-zoom, 1))`,
    backgroundColor: foundationSurface1,
    border: `calc(${borderWidthSm} / var(--flowchart-zoom, 1)) solid ${borderDefault}`,
    borderRadius: `calc(${borderRadiusSm} / var(--flowchart-zoom, 1))`,
    boxSizing: 'content-box',
    boxShadow: shadowCenterMedium,
    zIndex: 2,
  };

  return (
    <>
      <NodeResizer
        handleStyle={resizeHandleStyle}
        isVisible={isVisible}
        keepAspectRatio={shiftKeyPressed}
        lineStyle={resizeLineStyle}
        onResizeEnd={resetResizeDirection}
        onResizeStart={setResizeDirection}
      />
      <NodeResizer
        handleStyle={{ display: 'none' }}
        isVisible={isVisible}
        keepAspectRatio={shiftKeyPressed}
        lineClassName='line__invisible'
        lineStyle={invisibleResizeLineStyle}
        onResizeEnd={resetResizeDirection}
        onResizeStart={setResizeDirection}
      />
    </>
  );
};

export default ResizeControls;
