import {
  Background,
  BackgroundVariant,
  ConnectionLineType,
  ConnectionMode,
  DefaultEdgeOptions,
  EdgeTypes,
  MarkerType,
  MiniMap,
  NodeTypes,
  ReactFlow,
  SelectionMode,
  XYPosition,
  useReactFlow,
  useViewport,
} from '@xyflow/react';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { useFlowchart } from '../../../../contexts/FlowchartControlProvider';
import { useFlowchartHotkeys } from '../hooks/useFlowchartHotkeys';
import { copyFlowchartDataToClipboard, parseFlowchartData } from '../lib/copyPaste';
import { MAX_ZOOM, MIN_ZOOM } from '../lib/zoom';
import CanvasLoader from './CanvasLoader';
import { RESIZE_HANDLE_SIZE } from './constants';
import EditableLabelEdge from './edges/EditableLabelEdge';
import HelperLines from './HelperLines';
import ShapeNodeComponent from './nodes/ShapeNode/ShapeNode';
import FloatingPanel from './Panel/FloatingPanel';

const StyledReactFlow = styled(ReactFlow)<{ $hasSelectedShape: boolean }>`
  .react-flow__renderer {
    overflow: hidden;
  }

  .react-flow__handle {
    z-index: 1;
  }

  .react-flow__pane {
    cursor: ${({ $hasSelectedShape }) => ($hasSelectedShape ? 'crosshair' : 'grab')};
  }

  .react-flow__resize-control.line {
    ${({
      theme: {
        constants: { borderWidthSm },
      },
    }) => css`
      &.top {
        border-top-width: calc(${borderWidthSm} / var(--flowchart-zoom, 1));
      }
      &.right {
        border-right-width: calc(${borderWidthSm} / var(--flowchart-zoom, 1));
      }
      &.left {
        border-left-width: calc(${borderWidthSm} / var(--flowchart-zoom, 1));
      }
      &.bottom {
        border-bottom-width: calc(${borderWidthSm} / var(--flowchart-zoom, 1));
      }
    `}

    &.line__invisible {
      &.top,
      &.bottom {
        height: calc(${RESIZE_HANDLE_SIZE} / var(--flowchart-zoom, 1));
      }
      &.left,
      &.right {
        width: calc(${RESIZE_HANDLE_SIZE} / var(--flowchart-zoom, 1));
      }
    }
  }

  .hover-circle {
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
    opacity: 0;

    &:hover {
      display: flex;
      align-items: center;
      justify-content: center;
      .handle-circle {
        transform: scale(1.25);
      }
    }
  }

  .handle-circle {
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
    background-color: ${({ theme: { vars } }) => vars.accentSubdued4};
    pointer-events: none;
  }

  .shape-node.selected .hover-circle {
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const nodeTypes: NodeTypes = {
  shape: ShapeNodeComponent,
};

const edgeTypes: EdgeTypes = {
  editableLabelEdge: EditableLabelEdge,
};

const defaultEdgeOptions: DefaultEdgeOptions = {
  type: 'editableLabelEdge',
  markerEnd: { type: MarkerType.Arrow, color: '#2A2A2A', strokeWidth: 2 },
};

const proOptions = { account: 'paid-pro', hideAttribution: true };

type Props = {
  flowchartId?: number;
  isLoading: boolean;
};

const Flowchart = ({ flowchartId, isLoading }: Props) => {
  const { flowchartHandlers, readonly } = useFlowchart();
  const theme = useTheme();

  const {
    displayMiniMap,
    edges,
    nodes,
    onConnect,
    insertFlowchartData,
    onDragOver,
    onDrop,
    onEdgesChange,
    onEdgeClick,
    onEdgesDelete,
    onEdgeDoubleClick,
    onInit,
    onNodeDragStart,
    onNodesChange,
    onNodesDelete,
    onPaneClick,
    onReconnect,
    onSelectionDragStart,
    focusNodeLabelEditor,
    setEditingEdgeId,
    setShowEdgeLabelInputField,
    helperLineHorizontal,
    helperLineVertical,
    selectedShapeType,
  } = flowchartHandlers;

  const baseProps = [
    'edgesFocusable',
    'elementsSelectable',
    'elevateNodesOnSelect',
    'nodesConnectable',
    'nodesDraggable',
    'nodesFocusable',
  ].reduce((acc, key) => ({ ...acc, [key]: !readonly }), {});

  const readonlyProps = {
    ...baseProps,
    onConnect: !readonly ? onConnect : undefined,
    onPaneClick: !readonly ? onPaneClick : undefined,
  };

  const reactFlow = useReactFlow();
  const reactFlowRef = useRef<HTMLDivElement>(null);

  // The client X and Y of the mouse on the flowchart
  // When the mouse is outside the flowchart, mousePos is null
  const [mousePos, setMousePos] = useState<XYPosition | null>({ x: 0, y: 0 });

  const { useSelectedNodeHotkeys, useSelectedEdgeHotkeys } = useFlowchartHotkeys(reactFlowRef);

  useSelectedNodeHotkeys('enter', (node) => {
    focusNodeLabelEditor(node);
  });

  useSelectedEdgeHotkeys('enter', (edge) => {
    setEditingEdgeId(edge.id);
    setShowEdgeLabelInputField(true);
  });

  useEffect(() => {
    if (readonly || !mousePos || !flowchartId) {
      return;
    }

    const pasteHandler = (event: ClipboardEvent) => {
      const nodes = reactFlow.getNodes();
      const isEditing = nodes.find(({ data }) => data?.isEditing);
      if (isEditing) {
        return;
      }
      // Here you could get see if a file was pasted and create an image node
      const paste = (event.clipboardData || window.clipboardData).getData('text');
      const data = parseFlowchartData(paste);

      if (!data) {
        return;
      }

      // Paste is limited to the same flowchart the copy came from
      // This check can be removed once node connection data can be loaded on the fly
      if (data.flowchartData.flowchartId === flowchartId) {
        event.preventDefault();
        insertFlowchartData(mousePos, data);
      }
    };
    document.addEventListener('paste', pasteHandler);
    return () => document.removeEventListener('paste', pasteHandler);
  }, [flowchartId, readonly, reactFlow, mousePos, insertFlowchartData]);

  const mouseIsOnFlowchart = Boolean(mousePos);

  useEffect(() => {
    if (!(mouseIsOnFlowchart && flowchartId)) {
      return;
    }

    const copyHandler = (event: ClipboardEvent) => {
      // Prevent recursion, see ../lib/copyPaste
      if ((event?.target as Element)?.getAttribute?.('data-is-temporary-flowchart-copy-element')) {
        return;
      }

      const nodes = reactFlow.getNodes();
      const edges = reactFlow.getEdges();

      const isEditing = nodes.find(({ data }) => data?.isEditing);

      // Exit if text editing or mouse it outside flowchart
      if (isEditing || !mouseIsOnFlowchart) {
        return;
      }
      const selectedNodes = nodes.filter(({ selected }) => selected);

      // Copying requires at least one selected node
      if (selectedNodes.length === 0) {
        return;
      }

      event.preventDefault();
      const selectedEdges = edges.filter(({ selected }) => selected);
      copyFlowchartDataToClipboard(flowchartId, selectedNodes, selectedEdges);

      if (event.type === 'cut' && !readonly) {
        reactFlow.deleteElements({ nodes: selectedNodes, edges: selectedEdges });
      }
    };

    document.addEventListener('copy', copyHandler);
    document.addEventListener('cut', copyHandler);

    return () => {
      document.removeEventListener('copy', copyHandler);
      document.removeEventListener('cut', copyHandler);
    };
  }, [flowchartId, readonly, mouseIsOnFlowchart, reactFlow]);

  const { zoom } = useViewport();
  useEffect(() => {
    if (reactFlowRef.current) {
      reactFlowRef.current.style.setProperty('--flowchart-zoom', zoom.toString());
    }
  }, [zoom]);

  if (isLoading) return <CanvasLoader readonly={readonly} />;

  return (
    <StyledReactFlow
      $hasSelectedShape={selectedShapeType != null}
      connectionLineType={ConnectionLineType.SmoothStep}
      connectionMode={ConnectionMode.Loose}
      connectionRadius={25}
      defaultEdgeOptions={defaultEdgeOptions}
      deleteKeyCode={['Backspace', 'Delete']}
      edgeTypes={edgeTypes}
      edges={edges}
      maxZoom={MAX_ZOOM}
      minZoom={MIN_ZOOM}
      nodeTypes={nodeTypes}
      nodes={nodes}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onEdgeClick={onEdgeClick}
      onEdgeDoubleClick={onEdgeDoubleClick}
      onEdgesChange={onEdgesChange}
      onEdgesDelete={onEdgesDelete}
      onInit={onInit}
      onMouseLeave={() => {
        setMousePos(null);
      }}
      onMouseMove={(event) => {
        setMousePos({ x: event.clientX, y: event.clientY });
      }}
      onNodeDragStart={onNodeDragStart}
      onNodesChange={onNodesChange}
      onNodesDelete={onNodesDelete}
      onReconnect={onReconnect}
      onSelectionDragStart={onSelectionDragStart}
      panOnDrag={[0]}
      panOnScroll
      panOnScrollSpeed={0.7}
      proOptions={proOptions}
      ref={reactFlowRef}
      selectNodesOnDrag={false}
      selectionMode={SelectionMode.Partial}
      {...readonlyProps}
    >
      {!readonly && <FloatingPanel />}
      <Background
        color={theme.vars.textSubdued}
        gap={32}
        size={1}
        variant={BackgroundVariant.Dots}
      />
      <HelperLines horizontal={helperLineHorizontal} vertical={helperLineVertical} />
      {displayMiniMap && <MiniMap />}
    </StyledReactFlow>
  );
};

export default Flowchart;
