import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { useFlyoutContext } from './FlyoutProvider';

const FlyoutWrapper = styled.div`
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  overflow-y: 'auto';
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
`;

type Props = {
  children: ReactNode;
  className?: string;
};

const FloatingUIFlyout = ({ children, className }: Props) => {
  const { refs, open, floatingStyles, getFloatingProps } = useFlyoutContext();

  if (!open) return null;

  return (
    <FlyoutWrapper
      className={className}
      ref={refs.setFloating}
      style={floatingStyles}
      {...getFloatingProps()}
    >
      {children}
    </FlyoutWrapper>
  );
};

export default FloatingUIFlyout;
