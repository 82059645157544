import { FC, SVGAttributes } from 'react';

import { IndexedByShape, ShapeType } from '../../../../../../../types/Flowchart';
import Circle from './types/circle';
import Cylinder from './types/cylinder';
import Diamond from './types/diamond';
import Rectangle from './types/rectangle';
import Text from './types/text';
import Triangle from './types/triangle';

export const ShapeComponents: IndexedByShape<FC<ShapeProps>> = {
  rectangle: Rectangle,
  circle: Circle,
  diamond: Diamond,
  triangle: Triangle,
  cylinder: Cylinder,
  text: Text,
};

export type ShapeProps = {
  width: number;
  height: number;
} & SVGAttributes<SVGElement>;

export type ShapeComponentProps = Partial<ShapeProps> & { type: ShapeType; className?: string };
