import { JSONContent } from '@tiptap/core';
import CharacterCount from '@tiptap/extension-character-count';
import Color from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { CustomTextStyle } from '../../../../components/application/editor/tiptap_extensions/paragraph/textStyle';
import { useFlowchart } from '../../../../contexts/FlowchartControlProvider';
import initTranslations from '../../../../lib/initTranslations';
import suggestion from '../tiptap_extensions/connection_suggestions/suggestion';
import MentionBadge from '../tiptap_extensions/mention_badge';

const t = initTranslations('curriculums_view.flowchart');

type Props = {
  nodeId: string;
  handleUpdate: (content: JSONContent) => void;
  selected: boolean;
};

export const useShapeEditor = ({ nodeId, handleUpdate, selected }: Props) => {
  const {
    flowchartHandlers: { getNode, setNodeIsEditing },
  } = useFlowchart();
  const node = getNode(nodeId);
  const { palettes } = useTheme();

  // Store isEditing value so that in useEffect below we only act when the value changes
  const [previousIsEditing, setPreviousIsEditing] = useState(!node?.data.isEditing);

  // TODO: This is a temporary solution to prevent the editor from being editable when not selected
  const editable = selected;

  const onUpdate = useCallback(({ editor }) => handleUpdate(editor.getJSON()), [handleUpdate]);

  const onFocus = useCallback(() => {
    node && setNodeIsEditing(node, true);
  }, [node, setNodeIsEditing]);

  const editor = useEditor(
    {
      content: node?.data.label,
      extensions: [
        StarterKit.configure({
          listItem: false,
          orderedList: false,
          bulletList: false,
          blockquote: false,
        }),
        CustomTextStyle.configure({
          palettes,
        }),
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
          defaultAlignment: 'center',
        }),
        CharacterCount.configure({
          limit: 1000,
        }),
        Color,
        Mention.configure({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          suggestion,
        }),
        Placeholder.configure({
          placeholder: () => {
            if (node?.data?.type === 'text') {
              return t('shapes.editor_placeholder.text');
            }
            return t('shapes.editor_placeholder.shape');
          },
          includeChildren: true,
          showOnlyWhenEditable: node?.data?.type === 'text' ? false : true,
        }),
        MentionBadge,
        Link.configure({
          openOnClick: false,
          autolink: true,
        }),
      ],
      onUpdate,
      onFocus,
      editable,
    },
    [selected, node?.data?.type]
  );

  useEffect(() => {
    if (!node || !editor) {
      return;
    }

    // If the isEditing value has not changed, we do not need to focus or blur the editor
    if (previousIsEditing === node.data.isEditing) {
      return;
    }

    if (node.data.isEditing && !editor.isFocused) {
      editor.commands.focus('end');
    } else if (!node.data.isEditing && editor.isFocused) {
      editor.commands.blur();
    }
    setPreviousIsEditing(node.data.isEditing);
  }, [node, editor, previousIsEditing]);

  return { editor };
};
