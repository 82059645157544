import { useMergeRefs } from '@floating-ui/react';
import {
  NodeProps,
  useConnection,
  useStore,
  useUpdateNodeInternals,
  useViewport,
} from '@xyflow/react';
import React, { useCallback, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import { EditorProvider } from '../../../../../../contexts/EditorContext';
import { EditorToolbarProvider } from '../../../../../../contexts/EditorToolbarContext';
import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import { ShapeNode } from '../../../../../../types/Flowchart';
import { FlyoutProvider } from '../../../../shared/FloatingUIFlyout/FlyoutProvider';
import useFlyout from '../../../../shared/FloatingUIFlyout/useFlyout';
import ShapeToolbar from '../../../FlowchartEditor/ShapeToolbar/ShapeToolbar';
import { useShapeEditor } from '../../../hooks/useShapeEditor';
import ConnectionsIconsToolbar from '../../connections/ConnectionsFlyout/ConnectionsIconsToolbar';
import { ICONS_TOOLBAR_MIN_ZOOM } from '../../constants';
import EdgeHandles from '../EdgeHandles';
import ResizeControls from '../ResizeControls';
import NodeLabel from './NodeLabel';
import Shape from './shapes/Shape';

// this will return the current dimensions of the node (measured internally by react flow)
const useNodeDimensions = (id: string) => {
  const node = useStore((state) => state.nodeLookup.get(id));

  return {
    width: node?.width || 0,
    height: node?.height || 0,
  };
};

const StyledShape = styled(Shape)`
  stroke: ${({ theme: { vars } }) => vars.borderDefault};
  filter: drop-shadow(${({ theme: { vars } }) => vars.shadowCenterSmall});
`;

const ShapeNode = ({ id, selected = false, data }: NodeProps<ShapeNode>) => {
  const {
    flowchartHandlers: { getNode, setNodes, getNodeConnections, setNodeIsEditing },
    readonly,
  } = useFlowchart();
  const edgeConnection = useConnection();
  const updateNodeInternals = useUpdateNodeInternals();
  const { type, fontSize, backgroundColor } = data;
  const { width, height } = useNodeDimensions(id);
  const node = getNode(id);
  const hasConnections = getNodeConnections(id).length > 0;

  const { zoom } = useViewport();

  // We scale the size and position of several flowchart elements when zoom changes.
  // React Flow uses internal references of these values to perform calculations, and
  // this triggers the library to update those internal values when zoom changes.
  useEffect(() => {
    updateNodeInternals(id);
  }, [zoom, hasConnections, id, updateNodeInternals]);

  const {
    vars: { accentPrimaryDefault, borderDefault, foundationSurface1 },
  } = useTheme();

  const isConnecting = edgeConnection.inProgress;
  const isTarget = edgeConnection.toNode?.id === id;

  const handleUpdate = useCallback(
    (content) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === id) {
            return { ...node, data: { ...node.data, label: content } };
          }
          return node;
        })
      );
    },
    [id, setNodes]
  );

  const { editor } = useShapeEditor({ nodeId: id, handleUpdate, selected });

  useEffect(() => {
    if (!selected && data.isEditing && node) {
      setNodeIsEditing(node, false);
    }
  }, [selected, data.isEditing, setNodeIsEditing, node]);

  useEffect(() => {
    if (!data.isEditing) {
      editor?.commands.selectAll();
    }
  }, [data.isEditing, editor]);

  const shapeToolbarConnectionsFlyout = useFlyout({
    id: `flowchart-connections-flyout-${id}`,
    placement: 'right-start',
  });
  const iconsToolbarConnectionsFlyout = useFlyout({
    id: `flowchart-connections-icons-flyout-${id}`,
    placement: 'right-start',
  });
  const shapePositionRef = useMergeRefs([
    shapeToolbarConnectionsFlyout.refs.setPositionReference,
    iconsToolbarConnectionsFlyout.refs.setPositionReference,
  ]);

  if (!node || !editor) {
    return null;
  }

  const isSelected = selected && !readonly;
  const showShapeToolbar = !isConnecting && !readonly;
  const showIconsToolbar = hasConnections && zoom >= ICONS_TOOLBAR_MIN_ZOOM;

  return (
    <>
      <EditorProvider editor={editor}>
        <EditorToolbarProvider buttons={[]} context='docked'>
          {showShapeToolbar && (
            <FlyoutProvider flyout={shapeToolbarConnectionsFlyout}>
              <ShapeToolbar />
            </FlyoutProvider>
          )}
          {showIconsToolbar && (
            <FlyoutProvider flyout={iconsToolbarConnectionsFlyout}>
              <ConnectionsIconsToolbar />
            </FlyoutProvider>
          )}
          {!readonly && <ResizeControls isVisible={selected && !isConnecting} />}

          <div
            className={`shape-node ${selected && !isConnecting ? 'selected' : ''}`}
            ref={shapePositionRef}
          >
            <StyledShape
              fill={backgroundColor || foundationSurface1}
              height={height}
              stroke={isTarget ? accentPrimaryDefault : borderDefault}
              strokeMiterlimit={0}
              strokeOpacity={isTarget ? 1 : 0.5}
              strokeWidth={1}
              type={type}
              width={width}
            />
            <EdgeHandles isTargetNode={isTarget} showIconsToolbar={showIconsToolbar} />

            <NodeLabel fontSize={fontSize} nodeId={id} selected={isSelected} />
          </div>
        </EditorToolbarProvider>
      </EditorProvider>
    </>
  );
};

export default ShapeNode;
