import { findChildren } from '@tiptap/core';
import { Editor } from '@tiptap/react';
import {
  Connection,
  ControlLinePosition,
  Edge,
  EdgeMouseHandler,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnEdgesDelete,
  OnInit,
  OnNodeDrag,
  OnNodesChange,
  OnNodesDelete,
  SelectionDragHandler,
  XYPosition,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  reconnectEdge,
  useReactFlow,
} from '@xyflow/react';
import {
  Dispatch,
  DragEvent,
  DragEventHandler,
  MouseEvent,
  SetStateAction,
  useCallback,
  useReducer,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import {
  FlowchartState,
  reducer,
} from '../../components/application/flowcharts/FlowchartEditor/reducer';
import { useUndoRedo } from '../../components/application/flowcharts/hooks/useUndoRedo';
import {
  FlowchartPastableNodes,
  transformPastablePositions,
} from '../../components/application/flowcharts/lib/copyPaste';
import { getHelperLines } from '../../components/application/flowcharts/lib/getHelperLines';
import includes from '../../lib/includes';
import {
  ConnectionData,
  EditableLabelEdge,
  NodeConnection,
  ShapeNode,
  ShapeType,
  shapeTypes,
} from '../../types/Flowchart';

export type FlowchartHandlers = {
  canRedo: boolean;
  canUndo: boolean;
  connectionsData: FlowchartState['connectionsData'];
  displayMiniMap: boolean;
  editingEdgeId: string | null;
  edges: Edge[];
  nodes: ShapeNode[];
  onConnect: OnConnect;
  onDragOver: (evt: DragEvent<HTMLDivElement>) => void;
  onDrop: DragEventHandler;
  onEdgesChange: OnEdgesChange;
  onEdgeClick: EdgeMouseHandler;
  onEdgeDoubleClick: EdgeMouseHandler;
  onEdgesDelete: OnEdgesDelete;
  onInit: OnInit;
  onNodeDragStart: OnNodeDrag;
  onNodesChange: OnNodesChange;
  onNodesDelete: OnNodesDelete;
  onPaneClick: (event: MouseEvent) => void;
  onReconnect: (oldEdge: Edge, newConnection: Connection) => void;
  onSelectionDragStart: SelectionDragHandler;
  redo: () => void;
  setDisplayMiniMap: (displayMiniMap: boolean) => void;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  setEditingEdgeId: Dispatch<SetStateAction<string | null>>;
  setNodes: Dispatch<SetStateAction<Node[]>>;
  showEdgeLabelInputField: boolean;
  setShowEdgeLabelInputField: Dispatch<SetStateAction<boolean>>;
  storeConnectionsData: (connectionsData: ConnectionData[]) => void;
  getNode: (id: string) => ShapeNode | undefined;
  getNodeConnections: (nodeId: string | null) => NodeConnection[];
  undo: () => void;
  focusNodeLabelEditor: (nodeToFocus: Node) => void;
  helperLineHorizontal?: number;
  helperLineVertical?: number;
  fontSize: number;
  insertFlowchartData: (pos: XYPosition, data: FlowchartPastableNodes) => void;
  getFontSize: (id: string) => number;
  setFontSize: (newFontSize: number, nodeId: string) => void;
  addNodeConnection: ({
    nodeId,
    connection,
  }: {
    nodeId: string;
    connection: NodeConnection;
  }) => void;
  removeNodeConnection: ({
    nodeId,
    connection,
  }: {
    nodeId: string;
    connection: NodeConnection;
  }) => void;
  removeEditorMentions: ({
    editor,
    connection,
  }: {
    editor: Editor;
    connection: NodeConnection;
  }) => void;
  setShapeType: (nodeId: string, newShapeType: ShapeType) => void;
  setNodeIsEditing: (nodeToEdit: ShapeNode, isEditing: boolean) => void;
  handleShapeClick: (shapeType: ShapeType) => void;
  selectedShapeType: ShapeType | null;
  setSelectedShapeType: Dispatch<SetStateAction<ShapeType | null>>;
  activeResizeDirection: Set<ControlLinePosition>;
  setActiveResizeDirection: Dispatch<SetStateAction<Set<ControlLinePosition>>>;
};

const defaultNodes: ShapeNode[] = [];
const defaultEdges: Edge[] = [];

export const useFlowchartHandlers = () => {
  const { fitView, screenToFlowPosition } = useReactFlow();
  const { canRedo, canUndo, redo, undo, takeSnapshot } = useUndoRedo();

  const [nodes, setNodes] = useState<ShapeNode[]>(defaultNodes);
  const [edges, setEdges] = useState<Edge[]>(defaultEdges);
  const [fontSize, setFontSize] = useState(18);
  const [activeResizeDirection, setActiveResizeDirection] = useState<Set<ControlLinePosition>>(
    new Set()
  );

  const [{ connectionsData }, dispatch] = useReducer(reducer, {
    connectionsData: {},
  });

  const getNode = useCallback(
    (nodeId: string) => {
      return nodes.find(({ id }) => id === nodeId);
    },
    [nodes]
  );

  const getNodeConnections = useCallback(
    (nodeId: string | null): NodeConnection[] => {
      if (!nodeId) return [];
      const node = getNode(nodeId);

      return node?.data?.connections || [];
    },
    [getNode]
  );
  const [displayMiniMap, setDisplayMiniMap] = useState(false);
  const [editingEdgeId, setEditingEdgeId] = useState<string | null>(null);
  const [showEdgeLabelInputField, setShowEdgeLabelInputField] = useState(false);
  const [helperLineHorizontal, setHelperLineHorizontal] = useState<number | undefined>(undefined);
  const [helperLineVertical, setHelperLineVertical] = useState<number | undefined>(undefined);
  const [selectedShapeType, setSelectedShapeType] = useState<ShapeType | null>(null);
  const handleShapeClick = (shapeType: ShapeType) => {
    setSelectedShapeType(shapeType);
  };

  useHotkeys('esc', () => setSelectedShapeType(null));

  const storeConnectionsData = useCallback((connectionsData: ConnectionData[]) => {
    dispatch({ type: 'storeConnectionsData', connectionsData });
  }, []);

  const getFontSize = useCallback(
    (nodeId: string) => {
      const node = getNode(nodeId);
      return node?.data?.fontSize || fontSize;
    },
    [getNode, fontSize]
  );

  const setFontSizeAndUpdateNode = useCallback(
    (newFontSize, nodeId) => {
      setFontSize(newFontSize);
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === nodeId) {
            return { ...node, data: { ...node.data, fontSize: newFontSize } };
          }
          return node;
        })
      );
    },
    [setFontSize, setNodes]
  );

  const setShapeTypeAndUpdateNode = useCallback(
    (nodeId, newShapeType) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === nodeId) {
            return { ...node, data: { ...node.data, type: newShapeType } };
          }
          return node;
        })
      );
    },
    [setNodes]
  );

  const customApplyNodeChanges = useCallback(
    (changes: NodeChange<ShapeNode>[], nodes: ShapeNode[]): ShapeNode[] => {
      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined);
      setHelperLineVertical(undefined);

      let positionChange, dimensionsChange;

      if (changes.length === 1 && changes[0].type === 'position' && changes[0].dragging) {
        // Position change, with a single node being dragged
        positionChange = changes[0];
      } else if (
        // Resize that does not change position (e.g. bottom-right)
        changes.length === 1 &&
        changes[0].type === 'dimensions' &&
        changes[0].resizing !== false &&
        activeResizeDirection.size > 0
      ) {
        dimensionsChange = changes[0];
      } else if (
        // Resize that also changes position (e.g. top-left)
        changes.length === 2 &&
        changes[0].type === 'position' &&
        changes[1].type === 'dimensions' &&
        changes[1].resizing !== false
      ) {
        positionChange = changes[0];
        dimensionsChange = changes[1];
      }

      if (positionChange || dimensionsChange) {
        const helperLines = getHelperLines({
          positionChange,
          dimensionsChange,
          resizeDirection: activeResizeDirection,
          nodes,
        });

        // if we have a helper line for a position change, snap the node to the helper line position
        // by manipulating the node position inside the change object
        if (positionChange?.position) {
          positionChange.position.x = helperLines.snapPosition.x ?? positionChange.position.x;
          positionChange.position.y = helperLines.snapPosition.y ?? positionChange.position.y;
        }

        // if we have a helper line for a dimension change, snap the width / height to the helper line
        if (dimensionsChange?.dimensions) {
          dimensionsChange.dimensions.width =
            helperLines.snapDimensions.width ?? dimensionsChange.dimensions.width;
          dimensionsChange.dimensions.height =
            helperLines.snapDimensions.height ?? dimensionsChange.dimensions.height;
        }

        // if helper lines are returned, we set them so that they can be displayed
        setHelperLineHorizontal(helperLines.horizontal);
        setHelperLineVertical(helperLines.vertical);
      }

      return applyNodeChanges(changes, nodes);
    },
    [activeResizeDirection]
  );

  const onConnect: OnConnect = useCallback(
    (connection) => {
      takeSnapshot();

      setEdges((edges) => addEdge(connection, edges));
    },
    [setEdges, takeSnapshot]
  );

  const onEdgeDoubleClick: EdgeMouseHandler = useCallback((_event, edge) => {
    setEditingEdgeId((currentEditingEdgeId) => {
      const newEditingEdgeId = currentEditingEdgeId === edge.id ? null : edge.id;
      setShowEdgeLabelInputField(newEditingEdgeId !== null);
      return newEditingEdgeId;
    });
  }, []);

  const onEdgeClick: EdgeMouseHandler = useCallback(() => {
    setEditingEdgeId(null);
  }, []);

  const onNodesChange: OnNodesChange<ShapeNode> = useCallback(
    (changes) => {
      setNodes((currentNodes) => customApplyNodeChanges(changes, currentNodes));
    },
    [setNodes, customApplyNodeChanges]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => {
      setEdges((currentEdges) => applyEdgeChanges(changes, currentEdges));
    },
    [setEdges]
  );

  const onReconnect = useCallback(
    (oldEdge: Edge, newConnection: Connection) =>
      setEdges((edges) => reconnectEdge(oldEdge, newConnection, edges)),
    []
  );

  const onDragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();

    evt.dataTransfer.dropEffect = 'move';
  };

  const onNodeDragStart = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const nodeWithNewConnection = useCallback(
    (node: ShapeNode, connection: NodeConnection): ShapeNode => {
      return {
        ...node,
        data: {
          ...node.data,
          connections: [...(node.data.connections || []), connection],
        },
      };
    },
    []
  );

  const nodeWithRemovedConnection = useCallback(
    (node: ShapeNode, connectionToRemove: NodeConnection) => {
      const newConnections = node.data.connections.filter((connection: NodeConnection) => {
        const matchesGivenNode =
          connection.type === connectionToRemove.type && connection.id === connectionToRemove.id;
        return !matchesGivenNode;
      });

      return {
        ...node,
        data: {
          ...node.data,
          connections: newConnections,
        },
      };
    },
    []
  );

  const nodeContainsConnection = useCallback((node: ShapeNode, connection: NodeConnection) => {
    const existingConnections = node.data.connections || [];
    return existingConnections.some(
      (existingConnection: ConnectionData) =>
        connection.id === existingConnection.id && connection.type === existingConnection.type
    );
  }, []);

  const addNodeConnection: FlowchartHandlers['addNodeConnection'] = useCallback(
    ({ nodeId, connection }) =>
      setNodes((nodes) =>
        nodes.map((node) => {
          const isOtherNode = node.id !== nodeId;
          const newConnection = { type: connection.type, id: connection.id };
          if (isOtherNode || nodeContainsConnection(node, newConnection)) return node;
          return nodeWithNewConnection(node, newConnection);
        })
      ),
    [nodeContainsConnection, nodeWithNewConnection, setNodes]
  );

  const removeNodeConnection = useCallback(
    ({ nodeId, connection }) =>
      setNodes((nodes) =>
        nodes.map((node) =>
          node.id === nodeId ? nodeWithRemovedConnection(node, connection) : node
        )
      ),
    [nodeWithRemovedConnection]
  );

  const removeEditorMentions: FlowchartHandlers['removeEditorMentions'] = useCallback(
    ({ editor, connection }) => {
      const connectionMentions = findChildren(editor.state.tr.doc, (node) => {
        const {
          type: { name },
          attrs: { id, type },
        } = node;

        return name === 'mentionBadge' && type === connection.type && id === connection.id;
      });

      editor.commands.forEach(connectionMentions, (mention, { tr, commands }) => {
        const {
          pos,
          node: { nodeSize },
        } = mention;
        const from = tr.mapping.map(pos);
        const to = tr.mapping.map(pos + nodeSize);

        return commands.deleteRange({ from, to });
      });
    },
    []
  );

  const onNodesDelete: OnNodesDelete = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const onEdgesDelete: OnEdgesDelete = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const insertFlowchartData = useCallback(
    (pos: XYPosition, data: FlowchartPastableNodes) => {
      const position = screenToFlowPosition(pos);

      data = transformPastablePositions(position, data);

      const newNodes: ShapeNode[] = data.flowchartData.nodes;
      const newEdges: EditableLabelEdge[] = data.flowchartData.edges;

      setNodes((nodes) => {
        return [
          ...nodes.map((node) => ({ ...node, selected: false })),
          ...newNodes.map((node) => ({ ...node, selected: true })),
        ];
      });
      setEdges((edges) => {
        return [
          ...edges.map((edge) => ({ ...edge, selected: false })),
          ...newEdges.map((edge) => ({ ...edge, selected: true })),
        ];
      });
    },
    [screenToFlowPosition, setNodes, setEdges]
  );

  const setNodeIsEditing = useCallback(
    (nodeToEdit: ShapeNode, isEditing: boolean) => {
      if (!nodeToEdit || nodeToEdit.data.isEditing === isEditing) {
        return;
      }

      setNodes((nodes) =>
        nodes.map((node) => {
          return node === nodeToEdit ? { ...node, data: { ...node.data, isEditing } } : node;
        })
      );
    },
    [setNodes]
  );

  const focusNodeLabelEditor = useCallback(
    (nodeToFocus) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === nodeToFocus.id) {
            return { ...node, data: { ...node.data, isEditing: true } };
          } else if (node.selected || node.data.isEditing) {
            return { ...node, selected: false, data: { ...node.data, isEditing: false } };
          }
          return node;
        })
      );
    },
    [setNodes]
  );

  const addNewNode = useCallback((position, type: ShapeType) => {
    const newNode: ShapeNode = {
      id: Date.now().toString(),
      type: 'shape',
      position,
      selected: true,
      data: {
        type,
        label: null,
        fontSize: 18,
        isEditing: true,
        connections: [],
        backgroundColor: '#FFFFFF',
      },
      width: 200,
      height: 200,
    };

    setNodes((nodes) =>
      nodes
        .map((node) => {
          return node.selected || node.data.isEditing
            ? { ...node, selected: false, data: { ...node.data, isEditing: false } }
            : node;
        })
        .concat([newNode])
    );

    setEdges((prevEdges) => prevEdges.map((edge) => ({ ...edge, selected: false })));
  }, []);

  const onPaneClick = useCallback(
    (event: MouseEvent) => {
      const insertSelectedShapeType = (event: MouseEvent) => {
        if (selectedShapeType) {
          takeSnapshot();

          const position = screenToFlowPosition({ x: event.clientX, y: event.clientY });
          addNewNode(position, selectedShapeType);
          setSelectedShapeType(null);
        }
      };
      takeSnapshot();
      insertSelectedShapeType(event);
    },
    [addNewNode, screenToFlowPosition, selectedShapeType, takeSnapshot]
  );

  // this function is called when a node from the sidebar is dropped onto the react flow pane
  const onDrop: DragEventHandler = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();

    const type = evt.dataTransfer.getData('application/reactflow');

    // if the type is an empty string, it means that the drop event was not from the sidebar
    if (!type || !includes(shapeTypes, type)) return;

    takeSnapshot();

    // this will convert the pixel position of the node to the react flow coordinate system
    // so that a node is added at the correct position even when viewport is translated and/or zoomed in
    const position = screenToFlowPosition({ x: evt.clientX, y: evt.clientY });
    addNewNode(position, type);
  };

  const onSelectionDragStart: SelectionDragHandler = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const onInit: OnInit = useCallback(() => {
    if (nodes.length > 0) fitView();
  }, [nodes.length, fitView]);

  return {
    canRedo,
    canUndo,
    connectionsData,
    displayMiniMap,
    edges,
    editingEdgeId,
    nodes,
    onConnect,
    onDragOver,
    onDrop,
    onEdgesChange,
    onEdgeClick,
    onEdgeDoubleClick,
    onEdgesDelete,
    onInit,
    onNodeDragStart,
    onNodesChange,
    onNodesDelete,
    onPaneClick,
    onReconnect,
    onSelectionDragStart,
    redo,
    setDisplayMiniMap,
    setEdges,
    setEditingEdgeId,
    setNodes,
    showEdgeLabelInputField,
    setShowEdgeLabelInputField,
    storeConnectionsData,
    getNode,
    getNodeConnections,
    undo,
    focusNodeLabelEditor,
    helperLineHorizontal,
    helperLineVertical,
    fontSize,
    setFontSize: setFontSizeAndUpdateNode,
    getFontSize,
    addNodeConnection,
    removeNodeConnection,
    insertFlowchartData,
    removeEditorMentions,
    setShapeType: setShapeTypeAndUpdateNode,
    setNodeIsEditing,
    handleShapeClick,
    selectedShapeType,
    setSelectedShapeType,
    activeResizeDirection,
    setActiveResizeDirection,
  };
};
