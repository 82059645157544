import React from 'react';
import styled from 'styled-components';

import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import { NodeConnection } from '../../../../../../types/Flowchart';
import FloatingUIFlyout from '../../../../shared/FloatingUIFlyout/FloatingUIFlyout';
import ConnectionsFlyoutContent from './ConnectionsFlyoutContent';
import ConnectionsFlyoutList from './ConnectionsFlyoutList';

const StyledFloatingUIFlyout = styled(FloatingUIFlyout)`
  width: 30rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  box-shadow: ${({ theme: { vars } }) => vars.shadowCenterMedium};
`;

type Props = {
  connections: NodeConnection[];
};

const ConnectionsFlyout = ({ connections }: Props) => {
  const { readonly } = useFlowchart();

  return (
    <StyledFloatingUIFlyout className='nowheel nopan'>
      <ConnectionsFlyoutContent readonly={readonly} />
      <ConnectionsFlyoutList connections={connections} readonly={readonly} />
    </StyledFloatingUIFlyout>
  );
};

export default ConnectionsFlyout;
