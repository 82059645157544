import React, { ReactNode } from 'react';

import { useFlyoutContext } from './FlyoutProvider';

type Props = {
  children: ReactNode;
};

const FlyoutTrigger = ({ children }: Props) => {
  const {
    refs: { setReference },
    getReferenceProps,
  } = useFlyoutContext();

  return (
    <div ref={setReference} {...getReferenceProps()}>
      {children}
    </div>
  );
};

export default FlyoutTrigger;
