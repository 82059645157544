import { NodeToolbar, useNodeId } from '@xyflow/react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import { useFlowchart } from '../../../../../contexts/FlowchartControlProvider';
import initTranslations from '../../../../../lib/initTranslations';
import { Divider } from '../../../editor/toolbar';
import ToolbarButton from '../../../editor/toolbar/buttons/components/ToolbarButton';
import { useFlyoutContext } from '../../../shared/FloatingUIFlyout/FlyoutProvider';
import FlyoutTrigger from '../../../shared/FloatingUIFlyout/FlyoutTrigger';
import ConnectionsFlyout from '../../shared/connections/ConnectionsFlyout/ConnectionsFlyout';
import ChangeShapeToolbar from './ChangeShapeToolbar/ChangeShapeToolbar';
import ConnectionsButton from './ConnectionsFlyout/ConnectionsButton';
import ShapeColorPicker from './ShapeColorPicker/ShapeColorPicker';
import TextEditToolbar from './TextEditToolbar/TextEditToolbar';

const StyledNodeToolbar = styled(NodeToolbar)`
  display: flex;
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopMedium};
  align-items: center;
`;

const ToolbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const t = initTranslations('curriculums_view.flowchart.toolbar');

const ShapeToolbar = () => {
  const nodeId = useNodeId();
  const { editor } = useEditorContext();
  const {
    flowchartHandlers: { getNodeConnections, getNode },
  } = useFlowchart();
  const node = nodeId ? getNode(nodeId) : null;
  const nodeConnections = getNodeConnections(nodeId);
  const { open: connectionsFlyoutOpen } = useFlyoutContext();

  const [displayTextEditToolbar, setDisplayTextEditToolbar] = useState(false);
  const [displayShapeEditToolbar, setDisplayShapeEditToolbar] = useState(false);

  useEffect(() => {
    const handleFocus = () => setDisplayTextEditToolbar(true);
    editor.on('focus', handleFocus);

    // Clean up event listener on component unmount
    return () => {
      editor.off('focus', handleFocus);
    };
  }, [editor]);

  useEffect(() => {
    if (!node?.selected) {
      setDisplayTextEditToolbar(false);
      setDisplayShapeEditToolbar(false);
    }
  }, [node?.selected]);

  const closeShapeEditToolbar = () => {
    setDisplayShapeEditToolbar(false);
  };

  const onTextFormatButtonClick = () => {
    setDisplayTextEditToolbar(!displayTextEditToolbar);
    setDisplayShapeEditToolbar(false);
  };

  const onChangeShapeButtonClick = () => {
    setDisplayShapeEditToolbar(!displayShapeEditToolbar);
    setDisplayTextEditToolbar(false);
  };

  const canUseShapeOptions = node?.data?.type !== 'text';

  return (
    <ToolbarWrapper>
      <StyledNodeToolbar offset={55}>
        <ToolbarButton
          active={displayTextEditToolbar}
          ariaLabel={t('text_toolbar_aria_label')}
          iconName='text'
          id='text-format-dropdown-button'
          onClick={onTextFormatButtonClick}
        />
        {canUseShapeOptions && (
          <>
            <Divider />
            <ShapeColorPicker />
            <Divider />
            <ToolbarButton
              active={displayShapeEditToolbar}
              ariaLabel={t('change_shape_aria_label')}
              iconName='shapes'
              iconSize='sm'
              id='shape-change-dropdown-button'
              onClick={onChangeShapeButtonClick}
              weight='regular'
            />
            <Divider />
            <FlyoutTrigger>
              <ConnectionsButton active={connectionsFlyoutOpen} connections={nodeConnections} />
            </FlyoutTrigger>
            <ConnectionsFlyout connections={nodeConnections} />
          </>
        )}
      </StyledNodeToolbar>
      {displayTextEditToolbar && <TextEditToolbar />}
      {displayShapeEditToolbar && <ChangeShapeToolbar onClose={closeShapeEditToolbar} />}
    </ToolbarWrapper>
  );
};

export default ShapeToolbar;
