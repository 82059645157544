import { ControlLinePosition } from '@xyflow/react';

const RESIZE_DIRECTIONS = new Set<ControlLinePosition>(['top', 'right', 'bottom', 'left']);

export const getResizeDirection = (
  resizeControlClassList: DOMTokenList
): Set<ControlLinePosition> => {
  const resizeHandleClasses = new Set(resizeControlClassList);
  const result = new Set<ControlLinePosition>();

  RESIZE_DIRECTIONS.forEach((direction) => {
    if (resizeHandleClasses.has(direction)) {
      result.add(direction);
    }
  });

  return result;
};
