import React, { ReactNode, createContext, useContext } from 'react';

import useFlyout from './useFlyout';

type FlyoutContext = ReturnType<typeof useFlyout>;

type Props = {
  children: ReactNode;
  flyout: FlyoutContext;
};

const FlyoutContext = createContext({} as FlyoutContext);
export const useFlyoutContext = () => useContext(FlyoutContext);

const FlyoutProvider = ({ children, flyout }: Props) => {
  return <FlyoutContext.Provider value={flyout}>{children}</FlyoutContext.Provider>;
};

export { FlyoutProvider };
