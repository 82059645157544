import { EdgeProps, Position } from '@xyflow/react';

// This offset is the size of the gap between the edge and the shape node, which
// is based on the size and position of the node handle
const OFFSET_PX = 5;

type Args = Pick<
  EdgeProps,
  'sourceX' | 'sourceY' | 'sourcePosition' | 'targetX' | 'targetY' | 'targetPosition'
> & { zoom: number };

/**
 * This helper takes in the current coordinates and handle positions for an edge
 * source and target, and returns modified coordinates that reduce the gap between
 * the edge and node.
 *
 * @returns new x and y coordinates for the edge source and target
 */
export const getEdgeCoords = ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  zoom,
}: Args) => {
  const scaled_offset_px = OFFSET_PX / zoom;

  let newSourceX = sourceX;
  let newSourceY = sourceY;
  let newTargetX = targetX;
  let newTargetY = targetY;

  switch (sourcePosition) {
    case Position.Top:
      newSourceY += scaled_offset_px;
      break;
    case Position.Bottom:
      newSourceY -= scaled_offset_px;
      break;
    case Position.Left:
      newSourceX += scaled_offset_px;
      break;
    case Position.Right:
      newSourceX -= scaled_offset_px;
      break;
  }

  switch (targetPosition) {
    case Position.Top:
      newTargetY += scaled_offset_px;
      break;
    case Position.Bottom:
      newTargetY -= scaled_offset_px;
      break;
    case Position.Left:
      newTargetX += scaled_offset_px;
      break;
    case Position.Right:
      newTargetX -= scaled_offset_px;
      break;
  }

  return {
    sourceX: newSourceX,
    sourceY: newSourceY,
    targetX: newTargetX,
    targetY: newTargetY,
  };
};
