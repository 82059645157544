import { Editor, Range } from '@tiptap/react';
import { useNodeId } from '@xyflow/react';
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { useFlowchart } from '../../../../../contexts/FlowchartControlProvider';
import initTranslations from '../../../../../lib/initTranslations';
import useDebounce from '../../../../../lib/useDebounce';
import { useGetSearchCompanyResultsQuery } from '../../../../../redux/services/resourceApis/searches/searchesApi';
import { ConnectionData, NodeConnection } from '../../../../../types/Flowchart';
import { fontMd1, fontSm4 } from '../../../../styled/TypeSystem';
import { getConnectionFromSearchResult } from '../../lib/getConnectionFromSearchResult';
import MentionsPlaceholder from './MentionsPlaceholder';
import SearchResults from './SearchResults';

export const MIN_CHARACTERS_TO_SEARCH = 3;

const t = initTranslations('curriculums_view.flowchart.connections.mentions_flyout');

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 24rem;
  padding-top: ${({ theme: { constants } }) => constants.spacerMd2};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  box-shadow: ${({ theme: { vars } }) => vars.shadowCenterSmall};
`;

const HeaderWrapper = styled.div`
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const Header = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd1};
  /* Overriding line-height to match designs */
  line-height: normal;
`;

const Description = styled.div`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
  /* Overriding line-height to match designs */
  line-height: normal;
`;

const SearchResultsWrapper = styled.div`
  max-height: 12rem;
  overflow-y: auto;
`;

type ConnectionsListProps = {
  editor: Editor;
  range: Range;
  query: string;
};

export type ConnectionsListHandle = {
  onKeyDown: (x: { event: KeyboardEvent }) => boolean;
};

const ForwardFunction: ForwardRefRenderFunction<ConnectionsListHandle, ConnectionsListProps> = (
  { editor, range, query },
  ref
) => {
  const itemsRef = useRef<Map<number, HTMLElement>>(new Map());
  const [selectedIndex, setSelectedIndex] = useState(0);

  const debouncedQuery = useDebounce<string>(query, 500);
  const { data, isFetching, isUninitialized } = useGetSearchCompanyResultsQuery(
    {
      page: 1,
      search: debouncedQuery,
      filter: ['groups', 'people', 'subjects', 'topics', 'steps', 'tests', 'flowcharts'],
    },
    { skip: debouncedQuery.length < MIN_CHARACTERS_TO_SEARCH }
  );
  const items = useMemo(() => data?.paginatedResult || [], [data?.paginatedResult]);

  const flowchartNodeId = useNodeId();
  const {
    flowchartHandlers: { storeConnectionsData, addNodeConnection },
  } = useFlowchart();

  useEffect(() => {
    const node = itemsRef.current.get(selectedIndex);
    if (node) {
      node.scrollIntoView({ block: 'nearest' });
    }
  }, [selectedIndex]);

  const insertMentionBadge = useCallback(
    ({ id, type }: NodeConnection) => {
      editor
        .chain()
        .insertContentAt(range, [
          { type: 'mentionBadge', attrs: { id, type } },
          { type: 'text', text: ' ' },
        ])
        .focus()
        .run();
    },
    [editor, range]
  );

  const addMentionConnection = useCallback(
    (connection: ConnectionData) => {
      const { id, type } = connection;

      if (flowchartNodeId) {
        storeConnectionsData([connection]);
        addNodeConnection({ nodeId: flowchartNodeId, connection: { id, type } });
        insertMentionBadge({ id, type });
      }
    },
    [flowchartNodeId, storeConnectionsData, addNodeConnection, insertMentionBadge]
  );

  const selectItem = useCallback(
    (index: number) => {
      const item = items[index];

      if (item) {
        const connectionData = getConnectionFromSearchResult(item);
        addMentionConnection(connectionData);
      }
    },
    [items, addMentionConnection]
  );

  const upHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  }, [selectedIndex, items.length]);

  const downHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  }, [selectedIndex, items.length]);

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex);
  }, [selectedIndex, selectItem]);

  useEffect(() => setSelectedIndex(0), [items]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onKeyDown: (x) => {
          if (x.event.key === 'ArrowUp') {
            upHandler();
            return true;
          }

          if (x.event.key === 'ArrowDown') {
            downHandler();
            return true;
          }

          if (x.event.key === 'Enter') {
            enterHandler();
            return true;
          }

          return false;
        },
      };
    },
    [upHandler, downHandler, enterHandler]
  );

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header>{t('header')}</Header>
        <Description>{t('description')}</Description>
      </HeaderWrapper>
      {query.length < MIN_CHARACTERS_TO_SEARCH ? (
        <MentionsPlaceholder query={query} />
      ) : (
        <SearchResultsWrapper>
          <SearchResults
            isLoading={isFetching || isUninitialized}
            items={items}
            itemsRef={itemsRef}
            resultOnClick={addMentionConnection}
            selectedIndex={selectedIndex}
          />
        </SearchResultsWrapper>
      )}
    </Wrapper>
  );
};

const ConnectionsList = forwardRef(ForwardFunction);

export default ConnectionsList;
